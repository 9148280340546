import React from "react";
import { BrowserRouter, Outlet, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import AuthProvider from "./AuthProvider";
import { MainPage } from './MainPage';
import { TermsOfUse } from "./TermsOfUse";
import Login from "./Login";
import ApiTest from "./ApiTest";
import Logout from "./Logout";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { CoinGrayscaleSite } from "./CoinGrayscaleSite";
import { CryptoTokenSite } from "./CryptoTokenSite";
import { TokenWallet } from "./TokenWallet";
import Architecture from "./SimArchitecture";
import { SimErrors } from "./SimErrors";
import { SimTxEvents } from "./SimTxEvents";

function LayoutComponent() {

  return (
    <div>
      {/* <header>Header Content</header> */}
      <main>
        <Outlet /> {/* Nested routes render here */}
      </main>
      {/* <footer>Footer Content</footer> */}
    </div>
  );

}

function NotFoundPage() {
  // return <h1>404 - Page Not Found</h1>;
  return (
    <div>
      <Container fluid>
        <Button href="/" variant="secondary">⋘ Back</Button>
      </Container>
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">404 - Page Not Found</Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/architecture',
    element: <Architecture />,
  },
  {
    path: '/errors',
    element: <SimErrors />,
  },
  {
    path: '/events',
    element: <SimTxEvents />,
  },
  {
    path: '/token-site',
    element: <CryptoTokenSite />,
  },
  {
    path: '/wallet',
    element: <TokenWallet />,
  },
  {
    path: '/tos',
    element: <TermsOfUse />,
  },
]);


function App() {
  return (
    <AuthProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </AuthProvider>
  );
}

export default App;
