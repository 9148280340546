import { useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import { useAuth } from "./AuthProvider";
import { Col, Container, Dropdown, Nav, Row, Table } from "react-bootstrap";
import SimNavBar from "./SimNavBar";
import SimTokenTable from "./SimTokenTable.js";
import SimTxnTable from "./SimTxnTable.js";
import SimAccountTable from "./SimAccountTable.js";
import { useAuth } from "./AuthProvider.js";

export function MainPage() {
    const [selectedSymbol, setSelectedSymbol] = useState("ETH");
	const ref = useRef(null);
    const navigate = useNavigate();

    const { token } = useAuth();


    const updateChildren = () => {
        setTimeout(() => {
            // console.log("updateChildren ref.current: ", ref.current)
            // ref.current?.refresh();

            // navigate("/", { replace: true });

            window.location.reload();
        }, 1000)
	};

    function dialogClosed() {
        console.log("dialog closed")
        updateChildren();
    }

    // const handleKeyPress = useCallback((event) => {
    //     // console.log(`Key pressed: ${event.key}`);
    //     if (event.ctrlKey && event.keyCode === 80) {
    //         console.log("do print!!")
    //         event.preventDefault();
    //         event.stopPropagation();
    //         return false;
    //     }
    // }, []);
    // useEffect(() => {
    //     // attach the event listener
    //     document.addEventListener('keydown', handleKeyPress);
    //     // remove the event listener
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, [handleKeyPress]);


    // const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

    return <div id="page-top">
        <Outlet />

        <SimNavBar onDialogClose={dialogClosed}></SimNavBar>

        <Container fluid>
        {token && (
            <Row>
                <Col lg={2}><SimTokenTable symbolSelected={(symbol) => {setSelectedSymbol(symbol)}}/></Col>
                <Col lg={7}><SimTxnTable selectedSymbol={selectedSymbol} /></Col>
                <Col lg={3}><SimAccountTable ref={ref} selectedSymbol={selectedSymbol}/></Col>
            </Row>
        )}
        </Container>

    </div>
}
