import { Col, Container, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import CryptoTokenNavBar from "./CryptoTokenNavBar";
import { tokenColor } from "./utils";

export function CryptoTokenSite() {

    const [searchParams, setSearchParams] = useSearchParams();

    const symbol = searchParams.get("symbol");

    const tc = tokenColor(symbol);

    const bg = `linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 65%, ${tc} 100%)`

    return <div>

        <header style={{ opacity: 0.66, background: bg, color: "white" }}  className="masthead-coin">
            <CryptoTokenNavBar />
            <Container fluid className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <Row>
                    <Col>
                        <div class="d-flex justify-content-center">
                            <div class="text-center">
                                <h1 class="mx-auto my-0 text-uppercase">{symbol}</h1>
                                <h2 class="text-white-50 mx-auto mt-2 mb-5">Get your own {symbol} wallet and start trading {symbol} today!</h2>
                                <a class="btn" href="#about"><img className="appStoreBadge" src="/assets/Mac_App_Store_Badge_US-UK_RGB_blk.svg" /></a>
                                <a class="btn" href="#about"><img className="appStoreBadge" src="/assets/google-play-badge.png" /></a>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <img className="bitcoin" src="/assets/bitcoin.jpg" />
                    </Col>
                </Row>
            </Container>
        </header>

    </div>;
}