import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSearchParams } from "react-router-dom";

function CryptoTokenNavBar() {

    const [searchParams, setSearchParams] = useSearchParams();

    const symbol = searchParams.get("symbol");

    return (
        <Container fluid className="fixed-top">
            <Navbar expand="lg" clasName="navbar navbar-expand-lg navbar-dark shadow-5-strong">
                <Navbar.Brand className="nav-brand" href="#home">
                    {symbol}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-right ms-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#home">About</Nav.Link>
                        <Nav.Link href="#home">Features</Nav.Link>
                        <Nav.Link href="#home">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}

export default CryptoTokenNavBar;