import { Nav } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import useSWR from 'swr';
import { tokenColor } from './utils';
import axios from 'axios';
import { useAuth } from './AuthProvider';


const fetcher = ([url, token]) => {
    return axios
        .get(url, { headers: { Authorization: "Bearer " + token } })
        .then((res) => res.data);
}

function SimTokenTable({ symbolSelected }) {

    const { token } = useAuth();

    const {
        data: simData,
        error,
        isValidating,
    } = useSWR(['api/v1/sim', token], fetcher);

    function onTokenClick(e) {
        // var hash = e.target.parentElement.getAttribute("hash");
        var parent = e.target.parentElement;
        var symbol = parent.getAttribute("symbol");

        var rows = parent.parentElement.children;
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if (row === parent) {
                row.classList.add("table-selected-row");
            } else {
                row.classList.remove("table-selected-row");
            }
        }

        // props["symbol"] = symbol;
        symbolSelected(symbol)

        console.log("SYMBOL:", symbol)
    }


    if (error) return <div className='failed'>failed to load</div>;
    if (isValidating) return <div className="Loading">Loading...</div>;


    const tokens = Object.values(simData.tokens);

    tokens.sort((a, b) => {
        if (a.symbol === "ETH") {
            return Number.MIN_SAFE_INTEGER;
        }
        if (b.symbol === "ETH") {
            return Number.MAX_SAFE_INTEGER;
        }
        return a.symbol.localeCompare(b.symbol);
    })

    const tokenTrs = tokens.map((token, index) => {
        const inEth = token.symbol === "ETH";
        return <tr key={index} symbol={token.symbol} hash={token.address} onClick={onTokenClick}>
            <td style={{color: "black", opacity: 0.66, background: tokenColor(token.symbol)}} title={token.address}>
                {inEth ?
                    <i className="fa-brands fa-ethereum"></i>
                    : <a style={{color: "black"}} href={"/token-site?symbol=" + token.symbol}><i className="fa-solid fa-arrow-up-right-from-square" /></a>
                }
                &nbsp;&nbsp;&nbsp;{token.symbol}
            </td>
            {/* <td>{token.transactions.length === 0 ? "" : token.transactions.length}</td> */}
        </tr>
    })

    return (
        <div>
            <h3>Tokens</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        {/* <th># txn</th> */}
                    </tr>
                </thead>
                <tbody>
                    {tokenTrs}
                </tbody>
            </Table>
        </div>
    );
}

export default SimTokenTable;