import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { useState } from "react";
import axios from "axios";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";


const Login = () => {
    const { setToken } = useAuth();
    const [error, setError] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     axios.post('/api/auth/login',
    //         {
    //             username: "mp",
    //             password: "mp",
    //         },
    //     ).then((response) => {
    //         console.log("axios response", response.data);
    //         setToken(response.data.token);
    //         navigate("/", { replace: true });
    //     }, (error) => {
    //         console.log("axios error", error);
    //     })
    // });
    function doLogin() {
        // const handleLogin = () => {
        //     setToken(staticToken);
        //     navigate("/", { replace: true });
        // };

        // setTimeout(() => {
        //     handleLogin();
        // }, 3 * 1000);

        setToken(null);
        axios.defaults.headers.common["Authorization"] = null;

        axios.post('/api/auth/login',
            {
                username: username,
                password: password,
            },
        ).then((response) => {
            console.log("axios response", response.data);
            setError(null);
            setToken(response.data.token);

            localStorage.setItem('userid', response.data.userid);
            localStorage.setItem('givenName', response.data.givenName);
            localStorage.setItem('familyName', response.data.familyName);
            localStorage.setItem('roles', response.data.roles);
            localStorage.setItem('expiresAtMs', response.data.expiresAtMs);

            navigate("/", { replace: true });
        }, (error) => {
            console.log("axios error", error);
            setError("" + error);
        })
    }

    if (error) {
        return (
            <div>
                <Container fluid>
                    <Button href="/login" variant="secondary">⋘ Back</Button>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">Error: {error}</Alert>
                        </Col>
                    </Row>                    
                </Container>
            </div>
        );
    }
    return (
        <div>
            <Container fluid>
                <Button href="/" variant="secondary">⋘ Back</Button>
            </Container>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="username"
                                    placeholder="Username"
                                    onInput={e => setUsername(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    onInput={e => setPassword(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                        <Button onClick={doLogin} variant="primary" type="submit">
                            Login
                        </Button>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
};


export default Login;
