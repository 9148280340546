import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CreateAccountDialog } from './SimAccountCreateDialog';

import { useState } from 'react';
import { CreateTokenDialog } from './SimTokenCreateDialog';
import { TransferTokenDialog } from './SimTokenTransferDialog';
import { getEthExchangeRates } from './EthPrice';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthProvider';

function SimNavBar({ onDialogClose }) {
    // const { authToken } = useAuth();
    const [createAccountShow, setCreateAccountShow] = useState(false);
    const [createTokenShow, setCreateTokenShow] = useState(false);
    const [transferTokenShow, setTransferTokenShow] = useState(false);
    const { setToken } = useAuth();
    const navigate = useNavigate();

    const { token } = useAuth();

    var ethRate = -1.0;
    var eurRate = -1.0;

    var rateStatement = ""

    getEthExchangeRates(exchangeRates => {
        ethRate = exchangeRates.USD / exchangeRates.ETH
        eurRate = exchangeRates.EUR / exchangeRates.ETH
        rateStatement = "1 ETH / " + ethRate + " $ / " + eurRate + " €";
    })

    function dialogClosed() {
        setCreateAccountShow(false);
        setCreateTokenShow(false);
        setTransferTokenShow(false);
        onDialogClose();
    }

    function logout() {
        console.log("logout")
        axios.post('/api/auth/logout',
        ).then((response) => {
            console.log("axios response", response.data);
            setToken(undefined);
            localStorage.removeItem('userid');
            localStorage.removeItem('givenName');
            localStorage.removeItem('familyName');
            localStorage.removeItem('roles');
            localStorage.removeItem('expiresAtMs');

            navigate("/", { replace: true });
        }, (error) => {
            console.log("axios error", error);
            setToken(undefined);
            localStorage.removeItem('userid');
            localStorage.removeItem('givenName');
            localStorage.removeItem('familyName');
            localStorage.removeItem('roles');
            localStorage.removeItem('expiresAtMs');
            // setError("" + error);
            // alert("" + error);
            navigate("/", { replace: true });
        })
    }

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand className="nav-brand" href="/"><img height="32px" src="/perhs.com.logo.192.png" />&nbsp;&nbsp;&nbsp;ETH ERC20 Factory</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {token && (
                                <>
                                    <NavDropdown title="Tokens" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/">Transactions</NavDropdown.Item>
                                        <NavDropdown.Item href="" onClick={() => setCreateTokenShow(true)}>Create new Token...</NavDropdown.Item>
                                        <NavDropdown.Item href="" onClick={() => setTransferTokenShow(true)}>Transfer Tokens...</NavDropdown.Item>
                                        <NavDropdown.Divider></NavDropdown.Divider>
                                        {/* <NavDropdown.Item href="/errors">Errors</NavDropdown.Item> */}
                                    </NavDropdown>
                                    <NavDropdown title="Accounts" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="" onClick={() => setCreateAccountShow(true)}>Create new Account...</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="About" id="basic-nav-dropdown">
                                        {/* <Nav.Link href="/architecture">Architecture</Nav.Link> */}
                                        <Nav.Link target="_" href="https://etherscan.io/gastracker">ETH Gas Tracker</Nav.Link>
                                        <Nav.Link href="/tos">Terms and Conditions</Nav.Link>
                                        <Nav.Link href="https://pehrs.com">Copyright &copy; 2024 by Matti Pehrs</Nav.Link>
                                    </NavDropdown>
                                </>
                            )}
                            <Nav.Link><span title={rateStatement} className="rates">{rateStatement}</span></Nav.Link>
                        </Nav>
                        {!token && (
                            <Nav.Link href="/login">Login</Nav.Link>
                        )}
                        {token && (
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <CreateAccountDialog show={createAccountShow} onHide={() => dialogClosed()} />
            <CreateTokenDialog show={createTokenShow} onHide={() => dialogClosed()} />
            <TransferTokenDialog show={transferTokenShow} onHide={() => dialogClosed()} />
        </>
    );
}

export default SimNavBar;