import { formatEther } from 'ethers';
import Table from 'react-bootstrap/Table';

import useSWR from 'swr';
import { toBigInt } from 'web3-utils';
import { getEth2DollarRate, getEthExchangeRates } from './EthPrice';
import { useState } from 'react';
import { numberWithSpaces } from './utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const fetcher = ([url, token]) => {
    return axios
        .get(url, { headers: { Authorization: "Bearer " + token } })
        .then((res) => res.data);
}


function SimTxnTable({ selectedSymbol }) {

    const { token } = useAuth();

    var ethRate = -1.0;
    const navigate = useNavigate();

    const {
        data: simData,
        error,
        isValidating,
    } = useSWR(['api/v1/tx/symbol/' + selectedSymbol, token], fetcher);

    // getEthExchangeRates((exchangeRates) => {
    //     console.log("exchangeRates", exchangeRates);
    // });

    getEth2DollarRate((rate) => {
        // console.log("ETH rate", rate);
        ethRate = rate;
    })


    function onTxnClick(e) {
        var uuid = e.target.parentElement.getAttribute("uuid");
        console.log("TXN:", uuid)
        navigate("/events?uuid=" + uuid + "&symbol=" + selectedSymbol);
    }

    if (error) return <div className='failed'>failed to load</div>;
    if (isValidating) return <div className="Loading">Loading...</div>;


    const transactions = simData.map((txEvent, index) => {
        const msg = txEvent.msg;
        // const timestamp = txEvent.timestamp;
        const uuid = txEvent.uuid;
        const amount = txEvent.amount ? txEvent.amount : 0;
        const fromAccount = txEvent.fromAccount ? txEvent.fromAccount : "";
        const toAccount = txEvent.toAccount ? txEvent.toAccount : "";
        const symbol = txEvent.symbol ? txEvent.symbol : "";
        const gasUsed = txEvent.gasUsed ? txEvent.gasUsed : 0;
        const txHash = txEvent.txHash ? txEvent.txHash : 0;

        var amountInEther = "0";
        var amountInWei = "0";
        var amountInDollar = ""
        if (amount > 0) {
            amountInWei = "" + toBigInt(amount);
            amountInEther = (+formatEther(amountInWei)).toFixed(4)
            if (selectedSymbol === "ETH") {
                amountInDollar = (amountInEther * ethRate).toFixed(6);
                if (amountInDollar > 1.0) {
                    amountInDollar = (amountInEther * ethRate).toFixed(2);
                }
                amountInDollar = "(" + amountInDollar + "$)";
            }
        }

        var gasUsedEth = "0";
        var gasUsedDollar = "?"
        if (gasUsed > 0) {
            const gasUsedBig = toBigInt(gasUsed);
            const gasWei = "" + gasUsedBig;
            gasUsedEth = (+formatEther(gasWei)).toFixed(18)

            const gasUsedInt = parseInt(gasUsedBig)
            const gasUsedInEth = gasUsedInt / 10 ** 18;
            gasUsedDollar = (gasUsedInEth * ethRate).toFixed(18);
            if (gasUsedDollar > 1.0) {
                gasUsedDollar = (gasUsedInEth * ethRate).toFixed(2);
            }
        }

        return <tr key={index} uuid={uuid} onClick={onTxnClick}>
            <td>{msg}</td>
            <td>{fromAccount}</td>
            <td>{toAccount}</td>
            <td title={numberWithSpaces(amount) + " wei " + amountInDollar}>{amountInEther} {selectedSymbol}</td>
            <td title={"" + gasUsedEth + " ETH (" + gasUsedDollar + "$)"}>{gasUsed}</td>
        </tr>
    })

    return (
        <div>
            <h3>{selectedSymbol} Transactions</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Transaction</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                        <th>Gas Used (wei)</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions}
                </tbody>
            </Table>
        </div>
    );
}

export default SimTxnTable;