import { Button, Col, FormControl, FormGroup, FormLabel, Modal, Row } from "react-bootstrap";
import { Form } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { useState } from "react";
import axios from "axios";

export function TransferTokenDialog(props) {

    const navigate = useNavigate();

    const { token } = useAuth();
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);

    function onFormSubmit(event) {
        event.preventDefault();

        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries())

        const symbol = formDataObj.symbol;
        const fromAccount = formDataObj.fromAccount;
        const toAccount = formDataObj.toAccount;
        const amountExpression = formDataObj.amountExpression;

        setMsg("Transferring " + amountExpression + " " + symbol + " from " + fromAccount+ " to " + toAccount + " ...");

        axios.post('/api/v1/tokens/transfer',
            {
                symbol: symbol,
                fromAccount: fromAccount,
                toAccount: toAccount,
                amountExpression: amountExpression
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            console.log("axios response", response.data);
            setError(null);
            setMsg(null);
            props.onHide();
        }, (error) => {
            console.log("axios error", error);
            setError("" + error);
        })

        // fetch('/api/v1/tokens/transfer', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         symbol: symbol,
        //         fromAccount: fromAccount,
        //         toAccount: toAccount,
        //         amountExpression: amountExpression
        //     })
        // })
        //     .then((response) => {
        //         if (response.status < 200 || response.status >= 300) {
        //             // An error response
        //             response.json().then(reason => {
        //                 console.log("reason: ", reason)
        //                 var errors = JSON.parse(localStorage.getItem("errors"));
        //                 if (errors) {
        //                     // Append
        //                     errors.reasons.push(reason)
        //                 } else {
        //                     errors = {
        //                         reasons: [reason]
        //                     }
        //                 }
        //                 localStorage.setItem("errors", JSON.stringify(errors));

        //                 navigate("/errors");

        //             }).catch((reason) => {
        //                 console.log("ERROR reason: ", reason)
        //                 var errors = JSON.parse(localStorage.getItem("errors"));
        //                 if (errors) {
        //                     // Append
        //                     errors.reasons.push(reason)
        //                 } else {
        //                     errors = {
        //                         reasons: [reason]
        //                     }
        //                 }
        //                 localStorage.setItem("errors", JSON.stringify(errors));
        //                 navigate("/errors");
        //             })
        //         }
        //     })
        // props.onHide();
    }

    return <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Transfer Tokens
            </Modal.Title>
        </Modal.Header>
        {!error && msg && (
            <Modal.Body className="grid-example">{msg}</Modal.Body>
        )}
        {!error && !msg && (
            <Form onSubmit={onFormSubmit} role="form">
                <Modal.Body className="grid-example">
                    <Row>
                        <FormGroup as={Col} controlId="formGridSymbolName">
                            {/* <FormLabel>Symbol</FormLabel> */}
                            <FormControl name="symbol" type="text" placeholder="Token Symbol" />
                            {/* <FormLabel>From</FormLabel> */}
                            <FormControl name="fromAccount" type="text" placeholder="From Account Name" />
                            {/* <FormLabel>To</FormLabel> */}
                            <FormControl name="toAccount" type="text" placeholder="To Account Name" />
                            {/* <FormLabel>Amount</FormLabel> */}
                            <FormControl name="amountExpression" type="text" placeholder="Amount in wei (or *Ether)" />
                        </FormGroup>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        )}
        {error && (
            <Modal.Body className="grid-example">ERROR: {error}</Modal.Body>
        )}
    </Modal>;
}