import axios from "axios";
import { Button, Col, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import { Form } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { useState } from "react";

export function CreateAccountDialog(props) {

    const { token } = useAuth();
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);

    function onFormSubmit(event) {
        event.preventDefault();
        // console.log("Color value is :", this.state.color); 

        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries())

        const accountName = formDataObj.accountName;
        console.log("accountName", accountName)

        // fetch('/api/v1/accounts', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         accountName: accountName,
        //     })
        // })
        // props.onHide();

        setMsg("Creating account " + accountName +"...");

        axios.post('/api/v1/accounts',
            {
                accountName: accountName
            },
            { headers: { Authorization: "Bearer " + token } }
        ).then((response) => {
            console.log("axios response", response.data);
            setError(null);
            setMsg(null);
            props.onHide();
        }, (error) => {
            console.log("axios error", error);
            setError("" + error);
        })
    }

    return <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Create new Account
            </Modal.Title>
        </Modal.Header>
        {!error && msg && (
            <div>MSG: {msg}</div>
        )}
        {!error && !msg && (
            <Form onSubmit={onFormSubmit} role="form">
                <Modal.Body className="grid-example">
                    <Row>
                        <FormGroup as={Col} controlId="formGridAccountName">
                            {/* <FormLabel>Account Name</FormLabel> */}
                            <FormControl name="accountName" type="text" placeholder="Account Name" />
                        </FormGroup>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        )}
        {error && (
            <div>ERROR: {error}</div>
        )}
    </Modal>;
}