import Table from 'react-bootstrap/Table';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import SimNavBar from './SimNavBar';
import { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import useSWR from 'swr';
import { formatEther } from 'ethers';
import { toBigInt } from 'web3-utils';
import { getEth2DollarRate } from './EthPrice';
import { numberWithSpaces } from './utils';
import axios from 'axios';
import { useAuth } from './AuthProvider';


const fetcher = ([url, token]) => {
    return axios
        .get(url, { headers: { Authorization: "Bearer " + token } })
        .then((res) => res.data);
}


export function SimTxEventsTable({ selectedSymbol, uuid }) {

    const { token } = useAuth();

    const navigate = useNavigate();
    var ethRate = -1.0;

    const {
        data: txEvents,
        error,
        isValidating,
    } = useSWR(['api/v1/tx/uuid/' + uuid, token], fetcher);

    if (error) return <div className='failed'>failed to load</div>;
    if (isValidating) return <div className="Loading">Loading...</div>;

    getEth2DollarRate((rate) => {
        console.log("ETH rate", rate);
        ethRate = rate;
    })


    const transactions = txEvents.map(txEvent => {
        const msg = txEvent.msg;
        const timestamp = txEvent.timestamp;
        const uuid = txEvent.uuid;
        const amount = txEvent.amount ? txEvent.amount : 0;
        const fromAccount = txEvent.fromAccount ? txEvent.fromAccount : "";
        const toAccount = txEvent.toAccount ? txEvent.toAccount : "";
        const symbol = txEvent.symbol ? txEvent.symbol : "";
        const gasUsed = txEvent.gasUsed ? txEvent.gasUsed : 0;
        const txHash = txEvent.txHash ? txEvent.txHash : 0;

        // new Intl.DateTimeFormat("en-US").format(new Date(timestamp));
        const ts = (new Date(timestamp)).toISOString() 

        var amountInEther = "0";
        var amountInWei = "0";
        var amountInDollar = ""
        if (amount > 0) {
            amountInWei = "" + toBigInt(amount);
            amountInEther = (+formatEther(amountInWei)).toFixed(4)
            if (symbol=== "ETH") {
                amountInDollar = (amountInEther * ethRate).toFixed(6);
                if (amountInDollar > 1.0) {
                    amountInDollar = (amountInEther * ethRate).toFixed(2);
                }
                amountInDollar = "(" + amountInDollar + "$)";
            }
        }

        var gasUsedEth = "0";
        var gasUsedDollar = "?"
        if (gasUsed > 0) {
            const gasUsedBig = toBigInt(gasUsed);
            const gasWei = "" + gasUsedBig;
            gasUsedEth = (+formatEther(gasWei)).toFixed(18)

            const gasUsedInt = parseInt(gasUsedBig)
            const gasUsedInEth = gasUsedInt / 10 ** 18;
            gasUsedDollar = (gasUsedInEth * ethRate).toFixed(18);
            if (gasUsedDollar > 1.0) {
                gasUsedDollar = (gasUsedInEth * ethRate).toFixed(2);
            }
        }

        return <tr  uuid={uuid}>
            <td>{ts}</td>
            <td style={{maxWidth: 320}}>{msg}</td>
            <td>{fromAccount}</td>
            <td>{toAccount}</td>
            <td title={numberWithSpaces(amount) + " wei " + amountInDollar}>{amountInEther} {symbol}</td>
            <td title={"" + gasUsedEth + " ETH (" + gasUsedDollar + "$)"}>{gasUsed}</td>
        </tr>
    })
    return (
        <div>
            <h3><a href="/"><i className="fa-solid fa-chevron-left"></i></a>&nbsp;&nbsp;&nbsp;{selectedSymbol} Transaction Events {uuid}</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Timstamp</th>
                    <th>Transaction</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                        <th>Gas Used (wei)</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions}
                </tbody>
            </Table>
        </div>
    );
}

export function SimTxEvents() {

    const [searchParams, setSearchParams] = useSearchParams();

    const uuid = searchParams.get("uuid");
    const selectedSymbol = searchParams.get("symbol");

    const ref = useRef(null);

    const updateChildren = () => {
        console.log("updateChildren ref.current: ", ref.current)
        ref.current?.refresh();
    };

    function dialogClosed() {
        console.log("dialog closed")
        updateChildren();
    }

    return <div id="page-top">
        <Outlet />

        <SimNavBar onDialogClose={dialogClosed}></SimNavBar>

        <Container fluid>
            <Row>
                <Col lg={12}><SimTxEventsTable selectedSymbol={selectedSymbol} uuid={uuid} /></Col>
            </Row>
        </Container>

    </div>
}