import { formatEther } from "ethers";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { toBigInt } from 'web3-utils';
import { getEth2DollarRate, getEthExchangeRates } from "./EthPrice";
import { numberWithSpaces } from "./utils";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export function TokenWallet() {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data: accountsBalances,
        error,
        isValidating,
    } = useSWR('api/v1/accounts', fetcher);

    var ethRate = -1.0;
    var eurRate = -1.0;

    getEthExchangeRates(exchangeRates => {
        ethRate = exchangeRates.USD / exchangeRates.ETH
        eurRate = exchangeRates.EUR / exchangeRates.ETH
    })

    if (error) return <div className='failed'>failed to load</div>;
    if (isValidating) return <div className="Loading">Loading...</div>;

    const accountName = searchParams.get("account");

    const balance =
        accountsBalances
            .filter((account) => account.accountName === accountName)
            .map(account => account.balance)[0]

    // const account =
    //     accountsBalances
    //         .filter((account) => account.accountName === accountName)[0]

    // console.log("account", account)

    const res = Object.keys(balance).map(tokenSymbol => {

        const wei = balance[tokenSymbol];

        console.log("TOKEN", tokenSymbol, "wei", wei)

        var ether = "0";
        var balanceWei = "0";
        var amountInDollar = ""
        var amountInEuro = ""
        if (wei) {
            balanceWei = "" + toBigInt(wei);
            console.log("wei", balanceWei)

            ether = (+formatEther(balanceWei)).toFixed(4)
            if (tokenSymbol === "ETH") {
                amountInDollar = (ether * ethRate).toFixed(6);
                if (amountInDollar > 1.0) {
                    amountInDollar = (ether * ethRate).toFixed(2);
                }
                amountInDollar = "" + numberWithSpaces(amountInDollar) + " $";

                amountInEuro = (ether * eurRate).toFixed(6);
                if (amountInEuro > 1.0) {
                    amountInEuro = (ether * eurRate).toFixed(2);
                }
                amountInEuro = "" + numberWithSpaces(amountInEuro) + " €";
            }
        }

        return <tr>
            <td>{tokenSymbol}</td>
            <td>{ether} {tokenSymbol}</td>
            <td>{numberWithSpaces(wei)}</td>
            <td title={"1ETH = " + ethRate + " $"}>{amountInDollar}</td>
            <td title={"1ETH = " + eurRate + " $"}>{amountInEuro}</td>
        </tr>
    })

    return <Container fluid>
        <Row>
            <Col lg={12}>
                <h2>
                    <i className="fa-solid fa-user" /> ACCOUNT: {accountName}
                </h2>

                <Table>
                    <thead>
                        <tr>
                            <th>Token Symbol</th>
                            <th>Amount</th>
                            <th>Amount (wei)</th>
                            <th title="Only available for ETH">In Dollar</th>
                            <th title="Only available for ETH">In Euro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Container>
}