

// GET https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR

const endpoint = "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD,EUR";


export function getEth2DollarRate(callbackFn) {
    getEthExchangeRates(exchangeRates => {
        const rate = exchangeRates.USD / exchangeRates.ETH
        callbackFn(rate)
    })
}


export function getEthExchangeRates(callbackFn) {

    const DAY_IN_MS = (24 * 60 * 60 * 1000);

    const storedExchangeTsStr = localStorage.getItem("exchangeRatesTs");
    var storedExchangeTs = -1;
    if(storedExchangeTsStr) {
        storedExchangeTs = parseInt(storedExchangeTsStr);
    }
    // console.log("---------------------> storedExchangeTsStr", storedExchangeTsStr, storedExchangeTs)

    const storedExchangeStr = localStorage.getItem("exchangeRates");
    if (storedExchangeStr && (Date.now() - storedExchangeTs) < DAY_IN_MS) {
        callbackFn(JSON.parse(storedExchangeStr))
    } else {
        fetch(endpoint)
            .then(response => {
                if (response.ok) {
                    // console.log("PRICE RESPONSE", response)
                    response.json()
                        .then(exchangeRates => {
                            // console.log("PRICE JSON-TXT", exchangeRates)
                            // {"ETH":1,"USD":3149.04,"EUR":2926.96}
                            localStorage.setItem("exchangeRates", JSON.stringify(exchangeRates))
                            localStorage.setItem("exchangeRatesTs", Date.now())
                            callbackFn(exchangeRates)
                        })
                }
            });
    }
}