import Table from 'react-bootstrap/Table';
import { Outlet, useNavigate } from 'react-router-dom';
import SimNavBar from './SimNavBar';
import { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';


export function getErrorReasons() {
    return getErrors().reasons;
}


export function getErrors() {
    const errors = localStorage.getItem("errors");
    if (errors) {
        return JSON.parse(errors);
    } else {
        return {
            reasons: []
        };
    }
}

export function addError(reason) {
    var errors = JSON.parse(localStorage.getItem("errors"));
    if (errors) {
        // Append
        errors.reasons.push(reason)
    } else {
        errors = {
            reasons: [reason]
        }
    }
    localStorage.setItem("errors", JSON.stringify(errors));
}


export function clearErrors() {
    const errors = {
        reasons: []
    }
    localStorage.setItem("errors", JSON.stringify(errors));
}


export function SimErrorTable() {

    const navigate = useNavigate();

    var reasons = getErrorReasons();
    // .reverese();
    if (reasons) {
        reasons = reasons.reverse();
    }

    function clearErrorsBtnPressed(event) {
        clearErrors();
        navigate("/errors");
    }

    // reasons.sort((a, b) => {
    //     if (a.symbol === "ETH") {
    //         return Number.MIN_SAFE_INTEGER;
    //     }
    //     if (b.symbol === "ETH") {
    //         return Number.MAX_SAFE_INTEGER;
    //     }
    //     return a.symbol.localeCompare(b.symbol);
    // })

    const reasonTrs = reasons.flatMap((reason, index) => {
        const details = reason.details;

        var detailsText = details;
        var topPart = "";
        if (details.indexOf(":") !== -1) {
            const parts = details.split(":")
            detailsText = parts.slice(1).join(":");
            topPart = parts[0]
        }

        const stack = reason.stack;
        return [
            <tr style={{ fontSize: "0.9em", borderLeft: "1px solid white", borderBottom: "1px solid #00000000" }} key={index}>
                <td title={details}>
                    {topPart}
                </td>
            </tr>,
            <tr style={{ fontSize: "1.2em", borderBottom: "1px solid #77222200" }} key={index}>
                <td title={details}>
                    {detailsText}
                </td>
            </tr>,
            <tr style={{ fontSize: "0.7em" }} key={1000 + index}>
                <td title={stack}>
                    <pre>{stack}</pre>
                </td>
            </tr>
        ]
    })

    return (
        <div>
            <h3>Errors <Button variant="outline-primary" size="sm" onClick={clearErrorsBtnPressed}>Clear Errors</Button></h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Details/Stack Trace</th>
                        {/* <th>Stack Trace</th> */}
                    </tr>
                </thead>
                <tbody>
                    {reasonTrs}
                </tbody>
            </Table>
        </div>
    );
}

export function SimErrors() {

    const ref = useRef(null);

    const updateChildren = () => {
        console.log("updateChildren ref.current: ", ref.current)
        ref.current?.refresh();
    };

    function dialogClosed() {
        console.log("dialog closed")
        updateChildren();
    }

    return <div id="page-top">
        <Outlet />

        <SimNavBar onDialogClose={dialogClosed}></SimNavBar>

        <Container fluid>
            <Row>
                <Col lg={12}><SimErrorTable /></Col>
            </Row>
        </Container>

    </div>
}