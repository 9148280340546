function stringToHash(string) {

    let hash = 0;

    if (string.length === 0) return hash;

    for (var i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash;
}

// Inspiration https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
const retro = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]
const nightColors = ["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"];
const dutch = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];
const pastels = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]

const tokenColors = retro;

export function tokenColor(symbol) {
    if(symbol === "ETH") {
        return "#fff";
    }
    const tokenHash = stringToHash(symbol)

    const index = Math.abs(tokenHash) % tokenColors.length;

    return tokenColors[index];
}



export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}