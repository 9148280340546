import Table from 'react-bootstrap/Table';
import useSWR, { mutate } from 'swr';
import { toBigInt } from 'web3-utils';
import { formatEther } from 'ethers';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { getEth2DollarRate } from './EthPrice';
import { numberWithSpaces } from './utils';
import { useAuth } from './AuthProvider';
import axios from 'axios';

// const fetcher = (...args) => fetch(...args).then((res) => res.json());


const fetcher = ([url, token]) => {
    console.log("url", url);
    return axios
        .get(url, { headers: { Authorization: "Bearer " + token } })
        .then((res) => res.data);
}

// const fetcher = (url, token) =>
//     axios
//       .get(url, { headers: { Authorization: "Bearer " + token } })
//       .then((res) => res.data);


const SimAccountTable = forwardRef((props, ref) => {

    // const [error, setError] = useState(undefined);
    // const [isValidating, setIsValidating] = useState(undefined);
    // const [accountsBalances, setAccountsBalances] = useState([]);
    const { token } = useAuth();

    var ethRate = -1.0;

    getEth2DollarRate((rate) => {
        // console.log("ETH rate", rate);
        ethRate = rate;
    })

    const {
        data: accountsBalances,
        error,
        isValidating,
    } = useSWR(['api/v1/accounts', token], fetcher);
    // axios.get('/api/v1/accounts').then((response) => {
    //     console.log("axios response", response.data);
    //     setAccountsBalances(response.data);
    //     setError(null);
    // }, (error) => {
    //     console.log("axios error", error);
    //     setError("" + error);
    // })

    function onAccountClick(e) {
        var name = e.target.parentElement.getAttribute("name");
        console.log("name:", name)
    }

    useImperativeHandle(ref, () => ({
        refresh: () => {
            // We need to delay the refresh so the backend has time to update
            setTimeout(function () {
                mutate('api/v1/accounts', true);
            }, 200);
        }
    }));


    if (error) return <div className='failed'>failed to load</div>;
    if (isValidating) return <div className="Loading">Loading...</div>;


    const selectedSymbol = props.selectedSymbol;

    console.log(accountsBalances)

    accountsBalances.sort((a, b) => {
        if (a.accountName === "coinbase") {
            return Number.MIN_SAFE_INTEGER;
        }
        if (b.accountName === "coinbase") {
            return Number.MAX_SAFE_INTEGER;
        }
        return a.accountName.localeCompare(b.accountName);
    })


    const accounts = accountsBalances.map((account, index) => {

        const wei = account.balance[selectedSymbol];
        var ether = "0";
        var balanceWei = "0";
        var amountInDollar = ""
        if (wei) {
            balanceWei = "" + toBigInt(wei);
            ether = (+formatEther(balanceWei)).toFixed(4)
            if (selectedSymbol === "ETH") {
                amountInDollar = (ether * ethRate).toFixed(6);
                if (amountInDollar > 1.0) {
                    amountInDollar = (ether * ethRate).toFixed(2);
                }
                amountInDollar = "(" + numberWithSpaces(amountInDollar) + "$)";
            }
        }




        return <tr key={index} name={account.accountName} onClick={onAccountClick}>
            <td>
                <a href={"/wallet?account=" + account.accountName}><i className="fa-solid fa-user" /></a>&nbsp;&nbsp;&nbsp;
                {account.accountName}
            </td>
            <td title={numberWithSpaces(balanceWei) + " wei\n" + amountInDollar} >{ether} {selectedSymbol}</td>
        </tr>
    })

    return (
        <div>
            <h3>Account Balance {selectedSymbol}</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Account</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts}
                </tbody>
            </Table>
        </div>
    );
});

export default SimAccountTable;